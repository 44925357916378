/**
 *  Microtaskers
 *  Nom: ANDRIARILALAO Johny Lino
 *  Email: johny.andriarilalao@gmail.com
 */

import React, { lazy, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { SuspenseView } from 'public/shared/components/SuspenseView';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AppRoutes: React.FC = () => {
  const AdminRoutes = lazy(() => import('./AdminRoutes'));
  const PrivatesRoutes = lazy(() => import('./PrivatesRoutes'));

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route>
            <Route
              path="admin/*"
              element={
                <SuspenseView>
                  <AdminRoutes />
                </SuspenseView>
              }
            />
            <Route
              path="/*"
              element={
                <SuspenseView>
                  <PrivatesRoutes />
                </SuspenseView>
              }
            />
            <Route path="*" element={<Navigate to="/admin/auth/login" replace={true} />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer autoClose={10000} />
    </>
  );
};

export default AppRoutes;
